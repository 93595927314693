import { ExternalToast, toast } from '@/app/components/ui/Toast';
import { HTTPError, getToastErrorMessage } from '@/app/utils/errors';
import * as Sentry from '@sentry/nextjs';
import {
  DeepKeys,
  FieldMeta,
  FormApi,
  FormOptions,
  ReactFormApi,
  useForm,
} from '@tanstack/react-form';
import { notifyManager } from '@tanstack/react-query';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { isEqual } from 'lodash';
import {
  RefObject,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { ZodIssue } from 'zod';

// We choose this delimiter because:
//   * We can still use "word, word" in errors
//   * If we forget to split the individual errors out (normally by using
//     <FieldErrors>), it still looks okay-ish compared to alternatives
export const VALIDATION_ERROR_DELIMITER = ' , ';

const VALIDATOR = zodValidator({
  // This is the default one, copied from src. Putting it here for visibility
  // since we will probably want to tweak this later.
  transformErrors: (issues: ZodIssue[]) =>
    issues.map(issue => issue.message).join(VALIDATION_ERROR_DELIMITER),
});
export type ZodValidatorType = typeof VALIDATOR;

export type FormType<TFormData> = {
  formId: string;
  /**
   * Sync the field errors from a HTTPError to the form fields.
   */
  syncFieldErrorsToForm: SyncFieldErrorsToFormType<TFormData>;
  /**
   * Pass in form values and get back which ones differ from the default values.
   */
  parseDirtyValues: ParseDirtyValuesType<TFormData>;
} & FormApi<TFormData, ZodValidatorType> &
  ReactFormApi<TFormData, ZodValidatorType>;

function getAllValidationErrors<TFormData>(
  uiState: FormType<TFormData>['state'],
) {
  const fieldErrors = Object.values(uiState.fieldMeta).flatMap(
    (fieldMeta: FieldMeta) => fieldMeta.errors,
  );
  return [...uiState.errors, ...fieldErrors];
}

/** @deprecated Use useControlledForm or useToastForm instead */
export const useDeprecatedForm = <TFormData>(
  formRef: RefObject<HTMLFormElement>,
  opts?: FormOptions<TFormData, typeof VALIDATOR>,
  showToast = true,
): FormType<TFormData> => {
  const formId = useId();
  const toastId = useRef<string>();

  const form = useForm({ validatorAdapter: VALIDATOR, ...opts });
  const { fieldInfo, setFieldMeta } = form;
  const syncFieldErrorsToForm = useMemo(
    () => createSyncFieldErrorsToForm<TFormData>({ fieldInfo, setFieldMeta }),
    [fieldInfo, setFieldMeta],
  );

  /**
   * We use a custom `isDirty` check because the default one is... "singular".
   * - Our implementation allows the state to go back to false when the
   *   form state is the same as the default values.
   * - The default implementation will return true when/if any of the values
   *   have ever changed, basically a `isTouched` check, not a `isDirty` per se.
   * @see https://arc.net/l/quote/ckjcjyfo
   */
  const isDirty = form.useStore(
    ({ values }) => !isEqual(form.options.defaultValues, values),
  );

  const defaultValues = form.options.defaultValues;
  const parseDirtyValues = useMemo(
    () => createParseDirtyValues<TFormData>(defaultValues),
    [defaultValues],
  );

  const dismissToast = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
      toastId.current = undefined;
    }
  };

  useEffect(() => {
    if (!showToast) {
      return; // Ignore the toast logic if we opt out
    }
    if (!isDirty) {
      return dismissToast(); // Dismiss any existing toast
    }

    // We store the toastId in a ref so we can dismiss it later
    toastId.current = toast('Unsaved changes', {
      confirm: {
        label: 'Save',
        form: formId,
        onClick: () => {
          if (!form.store.state.canSubmit) {
            return toast.warning('Please check the form for errors');
          }

          // form.handleSubmit();
          formRef.current?.requestSubmit();
        },
      },
      deny: {
        label: 'Reset',
        onClick: () => {
          form.reset();
        },
      },
      // We want the toast to stay until the user interacts with it
      duration: Infinity,
    });

    return dismissToast; // Dismiss the toast when the component unmounts
  }, [isDirty, formRef, form, showToast, formId]);

  return useMemo(
    () => ({
      formId,
      syncFieldErrorsToForm,
      parseDirtyValues,
      ...form,
      runValidator: form.runValidator,
      setErrorMap: form.setErrorMap,
    }),
    [formId, syncFieldErrorsToForm, parseDirtyValues, form],
  );
};

type InternalFormState = { effect?: () => void } & (
  | { stage: 'CLEAN' }
  | { stage: 'DIRTY' }
  | { stage: 'PENDING' }
  | { stage: 'ERROR'; error?: unknown }
  | { stage: 'SUCCESS' }
);
type InternalFormEvent =
  | { name: 'TOUCH' }
  | { name: 'UNTOUCH' }
  | { name: 'SUBMIT' }
  | { name: 'FAILURE'; error?: unknown }
  | { name: 'SUBMIT_SUCCESS' }
  | { name: 'COMPLETE' };
type ReducerType = (
  state: InternalFormState,
  event: InternalFormEvent,
) => InternalFormState;
const reducerLogger =
  (enabled: boolean, reducer: ReducerType) =>
  (state: InternalFormState, event: InternalFormEvent) => {
    const result = reducer(state, event);
    if (enabled) {
      // eslint-disable-next-line no-console
      console.log(
        `Stage: ${state.stage} -> Event: ${event.name} -> Stage: ${result.stage}`,
      );
    }
    return result;
  };

type OnFormSubmitType<TFormData> = Required<
  FormOptions<TFormData, typeof VALIDATOR>
>['onSubmit'];
type OnFormSubmitInvalidType<TFormData> = Required<
  FormOptions<TFormData, typeof VALIDATOR>
>['onSubmitInvalid'];
type ExtraOptions = {
  disableAutomaticReset?: boolean;
  debug?: boolean;
  successTimeout?: number;
  onComplete?: () => void;
};
type ControlledFormOptions<TFormData> = Omit<
  FormOptions<TFormData, typeof VALIDATOR>,
  'onSubmit'
> & {
  /**
   * For the form to work correctly, you must pass in onSubmit and it must
   * return a promise that only resolves when the submit is complete.
   */
  onSubmit: (
    ...args: Parameters<OnFormSubmitType<TFormData>>
    // We are overwriting this to only accept functions that returns promises,
    // which this hook relies on for managing the toast
  ) => Promise<unknown>;
};
type ControlledFormReturn<TFormData> = FormType<TFormData> & {
  stage: InternalFormState['stage'];
  globalError?: unknown;
};

/**
 * This hooks wraps TanStack Form's useForm with some extra logic tied to our specific app and DAPI.
 *
 * This base version does not include any UI, like pending states, actually exposing error messages etc, you need to do that yourself by looking at `stage` and `globalError`.
 *
 * If you want a version where all UI is included via a toast, use `useFormToast` which layers that on top.
 *
 * @param formOptions Form options passed along to TanStack Form
 * @param formOptions.onSubmit Compared to the TanStack Form one, this has to be asynchronous. The form state is driven by what happens here, so if this throws, errors are synced to the form, when this is no longer pending, form goes into success.
 * @param extraOptions Extra options to tweak the behaviour
 * @param extraOptions.disableAutomaticReset If true form does not reset automatically on success
 * @param extraOptions.debug If true extra debug information will be printed to the console
 * @param extraOptions.successTimeout How many ms should we stay in SUCCESS before going back to CLEAN?
 * @param extraOptions.onComplete Called when transitioning out of SUCCESS, can be used to delay an action like closing a Dialog until the user had a chance to see the success state
 *
 * @returns The hook returns all values from TanStack Form, with wrapped versions of `handleSubmit` and `reset`, and also some extras, documented below.
 * @returns `formId` - The id that should be used for the form and any submit buttons.
 * @returns `stage` - The state machine stage the form is in: `CLEAN | DIRTY | PENDING | ERROR | SUCCESS`, should be used to render UI for the form
 * @returns `globalError` - Any global error that happens in `onSubmit` ends up here - Field errors are automatically synced to the fields instead
 * @returns `syncFieldErrorsToForm` - Errors in `onSubmit` automatically gets synced to fields, but for niche cases you can call this function to do that manually
 * @returns `parseDirtyValues` - Function to get dirty form values - Pass in current form values and get back the dirty ones compared to the `defaultValues`
 */
export const useControlledForm = <TFormData>(
  formOptions: ControlledFormOptions<TFormData>,
  {
    disableAutomaticReset = false,
    debug = false,
    successTimeout = 1500,
    onComplete,
    _onTransitionIntoClean,
    _onTransitionIntoError,
  }: ExtraOptions & {
    /** @private Not currently meant to be used outside of useToastForm */
    _onTransitionIntoClean?: () => void;
    /** @private Not currently meant to be used outside of useToastForm */
    _onTransitionIntoError?: () => void;
  } = {},
): ControlledFormReturn<TFormData> => {
  const formId = useId();
  const syncFieldErrorsToFormRef =
    useRef<SyncFieldErrorsToFormType<TFormData>>();

  const { onSubmit } = formOptions;
  const onSubmitWrapped: OnFormSubmitType<TFormData> = useCallback(
    async args => {
      dispatch({ name: 'SUBMIT' });

      let result;
      try {
        result = await onSubmit(args);
        dispatch({ name: 'SUBMIT_SUCCESS' });
      } catch (error) {
        // Note: Some global errors could also be form level validation errors
        //       and could theoretically be synced to the form level, but we
        //       currently don't know which ones are validation and which ones
        //       are other errors, so we only sync field level errors here.
        if (error instanceof HTTPError) {
          syncFieldErrorsToFormRef.current!(error);

          // This might capture errors that are expected too, so might need
          // to narrow it, but this should also help us find global errors
          // that should actually be fields errors
          if (error.globalErrors.length > 0) {
            Sentry.captureException(error.globalErrors);
          }
        } else {
          // This will also capture things like network errors, which are
          // expected so we might or might not want to narrow this down later
          Sentry.captureException(error);
        }

        dispatch({ name: 'FAILURE', error });
        return;
      }

      return result;
    },
    [onSubmit],
  );

  const onSubmitInvalid = formOptions.onSubmitInvalid;
  const onSubmitInvalidWrapped: OnFormSubmitInvalidType<TFormData> =
    useCallback(
      args => {
        // "Normal" FE-validation errors that happen eg. onChange wont trigger
        // the error mode, that happens here when the user actively tries to
        // submit with active validation-errors.
        dispatch({ name: 'FAILURE' });

        if (onSubmitInvalid) {
          return onSubmitInvalid(args);
        }
      },
      [onSubmitInvalid],
    );

  const form = useForm({
    validatorAdapter: VALIDATOR,
    ...formOptions,
    onSubmit: onSubmitWrapped,
    onSubmitInvalid: onSubmitInvalidWrapped,
  });

  // We use a lazy-initialized ref for this to get around code ordering issues,
  // form needs to be created first, but this is used in onSubmitWrapped which
  // is declared before form, but only used later
  if (!syncFieldErrorsToFormRef.current) {
    syncFieldErrorsToFormRef.current = createSyncFieldErrorsToForm<TFormData>({
      fieldInfo: form.fieldInfo,
      setFieldMeta: form.setFieldMeta,
    });
  }

  /**
   * This reducer might seem a bit unorthodox at first glance, the pattern is
   * borrowed Dan Abramovs implementation of hover cards at Bluesky:
   *   https://github.com/bluesky-social/social-app/pull/3547
   *
   * - Sections starts with a function describing a transition into this stage.
   * - After that are all valid transitions out from the stage.
   * - Some stages saves effect-functions to state. These are not executed in
   *   the reducer as this would break React rules, instead they are executed in
   *   an effect. This let's us co-locate state descriptions and side effects.
   */
  const [uiState, dispatch] = useReducer<ReducerType>(
    reducerLogger(debug, (state, event) => {
      // ----- CLEAN -----
      function toClean(): InternalFormState {
        return {
          stage: 'CLEAN',
          effect: state.stage !== 'CLEAN' ? _onTransitionIntoClean : undefined,
        };
      }
      if (state.stage === 'CLEAN') {
        if (event.name === 'TOUCH') {
          return toDirty();
        }
      }

      // ----- DIRTY -----
      function toDirty(): InternalFormState {
        return { stage: 'DIRTY' };
      }
      if (state.stage === 'DIRTY') {
        if (event.name === 'UNTOUCH') {
          return toClean();
        }
        if (event.name === 'SUBMIT') {
          return toPending();
        }
        if (event.name === 'FAILURE') {
          return toError(event);
        }
      }

      // -- PENDING --
      function toPending(): InternalFormState {
        return { stage: 'PENDING' };
      }
      if (state.stage === 'PENDING') {
        if (event.name === 'SUBMIT_SUCCESS') {
          return toSuccess();
        }
        if (event.name === 'FAILURE') {
          return toError(event);
        }
      }

      // ----- ERROR -----
      function toError(
        event: Extract<InternalFormEvent, { name: 'FAILURE' }>,
      ): InternalFormState {
        // TODO: Until we feel sure we expose all form errors to the user,
        //       we log all errors to the console so we have some means of
        //       debugging. When we do feel sure, we can uncomment the if.
        // if (debug) {
        // eslint-disable-next-line no-console
        console.log('Form error', {
          error: event.error,
          validationErrors: getAllValidationErrors(form.state),
        });
        // }

        return {
          stage: 'ERROR',
          // Only some errors are saved in state, we read validation errors
          // directly from the form
          error: event.error,
          effect: state.stage !== 'ERROR' ? _onTransitionIntoError : undefined,
        };
      }
      if (state.stage === 'ERROR') {
        if (event.name === 'FAILURE') {
          // Even if we are already in error, this might be _another_ error
          return toError({
            name: event.name,
            // Keep the old error if the event does not have a new one
            error: event.error || state.error || undefined,
          });
        }
        if (event.name === 'SUBMIT') {
          return toPending();
        }
        if (event.name === 'UNTOUCH') {
          return toClean();
        }
        // The only time TOUCH happens when we are in an error state is
        // when we've had a valiation error disappear
        if (event.name === 'TOUCH') {
          return toDirty();
        }
      }

      // ----- SUCCESS -----
      function toSuccess(): InternalFormState {
        return {
          stage: 'SUCCESS',
          effect: () => {
            if (!disableAutomaticReset) {
              // After invalidation/setQueryData, React Query updates the cache
              // immediately, but schedules the updates to observers for the
              // next task. By scheduling the form reset like this, we use the
              // same function RQ uses to put it _after_ the observer updates in
              // the task queue, making sure the defaultValues for the form has
              // had time to update so we avoid the form flickering old values.
              // Note: This was only a problem with queries in a parent passing
              // down data as props, queries in the same component as the form
              // would read the new data immediately when re-rendering.
              notifyManager.schedule(() => {
                form.reset();
              });
            }

            // The success state is useful for keeping the "Saved!" toast open
            // for a bit, or delaying closing a dialog to show a success state
            // inside, that's why we keep it open for a bit.
            const timeoutId = setTimeout(() => {
              onComplete?.();
              dispatch({ name: 'COMPLETE' });
            }, successTimeout);

            // ..but cancel if user transitions out of this state early
            return () => {
              clearTimeout(timeoutId);
              // We need to ensure onComplete is always called, in this case a bit early,
              // but that should be fine
              onComplete?.();
            };
          },
        };
      }
      if (state.stage === 'SUCCESS') {
        if (event.name === 'COMPLETE') {
          return toClean();
        }
        if (event.name === 'TOUCH') {
          return toDirty();
        }
      }

      return state;
    }),
    { stage: 'CLEAN' },
  );

  /**
   * We use a custom `isDirty` check because the default one is... "singular".
   * - Our implementation allows the state to go back to false when the
   *   form state is the same as the default values.
   * - The default implementation will return true when/if any of the values
   *   have ever changed, basically a `isTouched` check, not a `isDirty` per se.
   * @see https://arc.net/l/quote/ckjcjyfo
   */
  const { isDirty, canSubmit, isValid } = form.useStore(
    ({ values, canSubmit, isValid }) => ({
      isDirty: !isEqual(form.options.defaultValues, values),
      canSubmit,
      isValid,
    }),
  );
  const originalHandleSubmit = form.handleSubmit;
  const formReset = form.reset;

  const handleSubmit = useCallback(() => {
    if (!canSubmit) {
      dispatch({ name: 'FAILURE' });
      return Promise.resolve();
    }
    return originalHandleSubmit();
  }, [canSubmit, originalHandleSubmit]);

  const reset = useCallback(() => {
    formReset();
    // This would happen later anyway because of the syncDirtyToFormState-effect,
    // but we dispatch it explicitly here to make sure it happens batched
    // with the formReset to avoid race conditions
    dispatch({ name: 'UNTOUCH' });
  }, [formReset]);

  useEffect(
    function syncDirtyToFormState() {
      if (isDirty) {
        dispatch({ name: 'TOUCH' });
      } else {
        dispatch({ name: 'UNTOUCH' });
      }
    },
    [isDirty],
  );

  const uiStateError = uiState.stage === 'ERROR' ? uiState.error : undefined;
  useEffect(() => {
    if (uiState.stage === 'ERROR' && !uiStateError && isValid && isDirty) {
      // This handles the special case where we've gone from having a
      // validation error to not having it anymore
      dispatch({ name: 'TOUCH' });
    }
  }, [uiState.stage, uiStateError, isValid, isDirty]);

  // Execute effects saved in formState
  const effect = uiState?.effect;
  useEffect(() => {
    if (effect) {
      return effect();
    }
  }, [effect]);

  const defaultValues = form.options.defaultValues;
  const parseDirtyValues = useMemo(
    () => createParseDirtyValues<TFormData>(defaultValues),
    [defaultValues],
  );

  return useMemo(
    () => ({
      ...form,
      // This overrides the original handleSubmit
      handleSubmit,
      // This overrides the original reset
      reset,
      formId,
      stage: uiState.stage,
      globalError: uiState.stage === 'ERROR' ? uiState.error : undefined,
      syncFieldErrorsToForm: syncFieldErrorsToFormRef.current!,
      parseDirtyValues,
      // Makes TS stop complaining
      runValidator: form.runValidator,
      setErrorMap: form.setErrorMap,
    }),
    [formId, parseDirtyValues, form, handleSubmit, reset, uiState],
  );
};

/**
 * This hooks wraps useControlledForm and syncs its state to a toast so that all UI is taken care of.
 *
 * This JSDoc only documents the extra options, see useControlledForm for the rest of them.
 *
 * @param extraOptions.toastMessages Use this to configure the toast messages shown in different stages.
 * @param extraOptions.hideToast Set this to true to hide the toast. This is useful for hiding it when a dialog is opened on top, and then recover the correct state when the dialog is closed.
 */
export const useToastForm = <TFormData>(
  formOptions: ControlledFormOptions<TFormData>,
  {
    debug,
    disableAutomaticReset,
    toastMessages: toastMessagesArg = {},
    hideToast = false,
    successTimeout,
    onComplete,
  }: ExtraOptions & {
    toastMessages?: Partial<typeof DEFAULT_TOAST_MESSAGES>;
    hideToast?: boolean;
  } = {},
): FormType<TFormData> => {
  const toastId = useRef<string>();
  const toastMessages = { ...DEFAULT_TOAST_MESSAGES, ...toastMessagesArg };

  const dismissToast = useCallback(() => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
      toastId.current = undefined;
    }
  }, []);

  const _onTransitionIntoError = useCallback(() => {
    if (toastId.current) {
      toast.update(toastId.current, { jiggle: true });
    }
  }, []);

  const form = useControlledForm(formOptions, {
    debug,
    disableAutomaticReset,
    successTimeout,
    onComplete,
    _onTransitionIntoClean: dismissToast,
    _onTransitionIntoError,
  });
  const { handleSubmit, stage, globalError } = form;

  const { canSubmit, isValid } = form.useStore(({ canSubmit, isValid }) => ({
    canSubmit,
    isValid,
  }));
  const wrappedSave = useCallback(
    (event: { preventDefault: () => void }) => {
      event.preventDefault();

      if (!canSubmit && toastId.current) {
        toast.update(toastId.current, { jiggle: true });
      }

      return handleSubmit();
    },
    [canSubmit, handleSubmit],
  );

  const formToasts = useMemo(() => {
    return {
      unsavedToast: () =>
        ({
          message: toastMessages.unsaved,
          intent: 'info',
          confirm: {
            label: toastMessages.save,
            onClick: wrappedSave,
            form: form.formId,
          },
          deny: {
            label: toastMessages.reset,
            onClick: form.reset,
          },
          disableCloseAction: true,
          duration: Infinity,
        }) as const,
      // TODO: We might want to make this a bit more advanced, for example:
      //       - Make it expandable so users always has access to all errors
      //       - Click/touch focuses the first field with errors
      //       - Improve a11y - Make keyboard accessible
      errorToast: (message: string) =>
        ({
          message,
          intent: 'error',
          confirm: {
            label: toastMessages.tryAgain,
            onClick: wrappedSave,
            form: form.formId,
          },
          deny: {
            label: toastMessages.reset,
            onClick: form.reset,
          },
          disableCloseAction: true,
          duration: Infinity,
          jiggle: false,
        }) as const,
      pendingToast: () =>
        ({
          intent: 'info',
          message: toastMessages.pending,
          confirm: undefined,
          deny: undefined,
          duration: Infinity,
          jiggle: false,
        }) as const,
      successToast: () =>
        ({
          message: toastMessages.success,
          intent: 'success',
          confirm: undefined,
          deny: undefined,
          duration: Infinity,
          jiggle: false,
        }) as const,
    };
  }, [
    toastMessages.unsaved,
    toastMessages.save,
    toastMessages.reset,
    toastMessages.tryAgain,
    toastMessages.pending,
    toastMessages.success,
    wrappedSave,
    form.formId,
    form.reset,
  ]);

  useEffect(
    function syncStateToToast() {
      if (hideToast) {
        dismissToast();
        return;
      }

      function showOrUpdateToast(
        newToast: ExternalToast & { message: string },
      ) {
        if (toastId.current) {
          toast.update(toastId.current, newToast);
        } else {
          const { message, ...options } = newToast;
          toastId.current = toast(message, options);
        }
      }

      // We don't have one for CLEAN, instead we close the toast imperatively
      // in an effect when moving into the CLEAN stage, see reducer
      if (stage === 'DIRTY') {
        showOrUpdateToast(formToasts.unsavedToast());
      } else if (stage === 'PENDING') {
        showOrUpdateToast(formToasts.pendingToast());
      } else if (stage === 'ERROR') {
        // Prio:
        //   - Unknown errors & Global DAPI errors
        //   - If form invalid - 'Please check ...'
        //   - If form valid - 'Could not save ...'
        const errorMessage = getToastErrorMessage(
          globalError,
          isValid ? toastMessages.unknownError : toastMessages.validationError,
        );
        showOrUpdateToast(formToasts.errorToast(errorMessage));
      } else if (stage === 'SUCCESS') {
        showOrUpdateToast(formToasts.successToast());
      }
    },
    [
      stage,
      globalError,
      formToasts,
      isValid,
      toastMessages.unknownError,
      toastMessages.validationError,
      hideToast,
      dismissToast,
    ],
  );

  useEffect(() => {
    return () => {
      dismissToast();
    };
  }, [dismissToast]);

  return form;
};

const DEFAULT_TOAST_MESSAGES = {
  unsaved: 'Unsaved changes',
  save: 'Save',
  reset: 'Reset',
  tryAgain: 'Try again',
  pending: 'Saving changes...',
  success: 'Saved!',
  unknownError: 'Could not save settings',
  validationError: 'Please check the form for errors',
};

function createSyncFieldErrorsToForm<TFormData>({
  fieldInfo,
  setFieldMeta,
}: Pick<FormApi<TFormData, ZodValidatorType>, 'fieldInfo' | 'setFieldMeta'>) {
  type FieldKeys = DeepKeys<TFormData>;

  return function syncFieldErrorsToForm(error: HTTPError) {
    for (const fieldError of error.fieldErrors) {
      if (Object.keys(fieldInfo).includes(fieldError.meta.param_name)) {
        setFieldMeta(fieldError.meta.param_name as FieldKeys, oldMeta => {
          const oldMessage = oldMeta.errorMap.onSubmit
            ? oldMeta.errorMap.onSubmit + VALIDATION_ERROR_DELIMITER
            : '';
          const newMessage = fieldError.long_message || fieldError.message;
          return {
            ...oldMeta,
            errorMap: {
              onSubmit: oldMessage + newMessage,
            },
          };
        });
      } else {
        Sentry.captureException(
          new Error(
            `Form: DAPI returned error with param_name: "${fieldError.meta.param_name}" which has no field on form - This error will not be exposed to the user`,
          ),
        );
      }
    }
  };
}

type SyncFieldErrorsToFormType<TFormData> = ReturnType<
  typeof createSyncFieldErrorsToForm<TFormData>
>;

function createParseDirtyValues<TFormData>(
  defaultValues: TFormData | undefined,
) {
  return (values: TFormData): Partial<TFormData> => {
    if (!defaultValues || !values) {
      return {};
    }

    const dirtyValues = Object.entries(values).reduce(
      (accumulator, [key, currentValue]) => {
        if (!isEqual(currentValue, defaultValues[key])) {
          accumulator[key] = currentValue;
        }
        return accumulator;
      },
      {},
    );

    return dirtyValues;
  };
}

type ParseDirtyValuesType<TFormData> = ReturnType<
  typeof createParseDirtyValues<TFormData>
>;
